/* Scss Document */
/* Magnific Popup CSS */
/* Magnific Popup CSS */
.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1042;
	overflow: hidden;
	position: fixed;
	background: #0b0b0b;
	opacity: 0.95;
}

.mfp-wrap {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1043;
	position: fixed;
	outline: none !important;
	-webkit-backface-visibility: hidden;
}

.mfp-container {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: hidden;
	padding: 0 8px;
	box-sizing: border-box;
}

.mfp-container:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
	display: none;
}

.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto;
}

.mfp-ajax-cur {
	cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: -moz-zoom-out;
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
}

.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
	cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.mfp-loading.mfp-figure {
	display: none;
}

.mfp-hide {
	display: none !important;
}

.mfp-preloader {
	color: #CCC;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -0.8em;
	left: 8px;
	right: 8px;
	z-index: 1044;
}

.mfp-preloader a {
	color: #CCC;
}

.mfp-preloader a:hover {
	color: #FFF;
}

.mfp-s-ready .mfp-preloader {
	display: none;
}

.mfp-s-error .mfp-content {
	display: none;
}

button.mfp-close,
button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
	display: block;
	outline: none;
	padding: 0;
	z-index: 1046;
	box-shadow: none;
	touch-action: manipulation;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.mfp-close {
	width: 44px;
	height: 44px;
	line-height: 44px;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
	opacity: 1;
}

.mfp-close:active {
	top: 1px;
}

.mfp-close-btn-in .mfp-close {
	color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	color: #FFF;
	right: -6px;
	text-align: right;
	padding-right: 6px;
	width: 100%;
}

.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #CCC;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap;
}

.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 90px;
	height: 110px;
	-webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
	margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
	opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
	margin-top: 35px;
	margin-left: 35px;
	border: medium inset transparent;
}

.mfp-arrow:after {
	border-top-width: 13px;
	border-bottom-width: 13px;
	top: 8px;
}

.mfp-arrow:before {
	border-top-width: 21px;
	border-bottom-width: 21px;
	opacity: 0.7;
}

.mfp-arrow-left {
	left: 0;
}

.mfp-arrow-left:after {
	border-right: 17px solid #FFF;
	margin-left: 31px;
}

.mfp-arrow-left:before {
	margin-left: 25px;
	border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
	right: 0;
}

.mfp-arrow-right:after {
	border-left: 17px solid #FFF;
	margin-left: 39px;
}

.mfp-arrow-right:before {
	border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
	top: -40px;
}

.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #000;
}

/* Main image in popup */
img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	box-sizing: border-box;
	padding: 40px 0 40px;
	margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
	line-height: 0;
}

.mfp-figure:after {
	content: '';
	position: absolute;
	left: 0;
	top: 40px;
	bottom: 40px;
	display: block;
	right: 0;
	width: auto;
	height: auto;
	z-index: -1;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #444;
}

.mfp-figure small {
	color: #BDBDBD;
	display: block;
	font-size: 12px;
	line-height: 14px;
}

.mfp-figure figure {
	margin: 0;
	background: none !important;
}

.mfp-bottom-bar {
	margin-top: -36px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto;
}

.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #F3F3F3;
	word-wrap: break-word;
	padding-right: 36px;
}

.mfp-image-holder .mfp-content {
	max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {

	/**
       * Remove all paddings around the image on small screen
       */
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0;
	}

	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}

	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0;
	}

	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px;
	}

	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		box-sizing: border-box;
	}

	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0;
	}

	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px;
	}

	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0;
	}
}

@media all and (max-width: 900px) {
	.mfp-arrow {
		-webkit-transform: scale(0.75);
		transform: scale(0.75);
	}

	.mfp-arrow-left {
		-webkit-transform-origin: 0;
		transform-origin: 0;
	}

	.mfp-arrow-right {
		-webkit-transform-origin: 100%;
		transform-origin: 100%;
	}

	.mfp-container {
		padding-left: 6px;
		padding-right: 6px;
	}
}



.mfp-with-zoom.mfp-ready .white-popup {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.mfp-with-zoom .white-popup {
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
}

/* animate out */
.mfp-with-zoom.mfp-removing .white-popup {
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 0;
}

/* Dark overlay, start state */
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}

/* animate in */
.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}

/* animate out */
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}

//////////////////////////////////////////////CUSTOM
.white-popup {
	position: relative;
	width: auto;
	max-width: 720px;
	margin: 0 auto;
	background: #fff;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
	padding: 0 30px 30px;
}

.width-medium {
	max-width: 420px;
}

.title_popup,
.title-popup {
	font-size: 20px;
	position: relative;
	height: 60px;
	padding: 0 15px;
	line-height: 60px;
	margin: 0 -30px 25px;
	font-family: $font-title;
	@extend %font-fea;
	font-weight: bold;
	width: calc(100% + 60px);
	float: none;

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 2px;
		bottom: 0;
		left: 0;
		background-image: -moz-linear-gradient(left, #0799ff #9dfeaf);
		background-image: -webkit-gradient(linear, left top, right top, from(#16d1f2), to(#9dfeaf));
		background-image: -webkit-linear-gradient(left, #0799ff, #9dfeaf);
		background-image: -o-linear-gradient(left, #0799ff, #9dfeaf);
	}
}

.quiz_tpl_4_full {
	.title-popup {
		margin: -20px -20px 20px -20px !important;
		width: calc(100% + 40px) !important;
	}
}

.content-popup,
.content_poup {
	font-size: 18px;
	line-height: 1.6;

	.header-content {
		border-bottom: 1px solid #E5E5E5;
		margin-bottom: 15px;
		padding-bottom: 15px;
	}

	p {
		margin-bottom: 30px;
	}

	.bullet {
		margin-bottom: 20px;
		position: relative;
		padding-left: 10px;

		&::before {
			content: "";
			position: absolute;
			width: 5px;
			height: 5px;
			background: #999;
			border-radius: 50%;
			left: 0;
			top: 10px;
		}
	}

	.input-form {
		.title-input {
			font-size: 18px;
			color: #222;
		}
	}
}

/*down-app-popup*/
.down-app-popup {
	.content-popup {
		padding: 0 30px;
		font-size: 0;
		align-items: center;
	}

	.left {
		text-align: right;
		flex-direction: column;

		a {
			display: block;

			&:first-child {
				margin-bottom: 10px;
			}

			img {
				float: right;
				width: 135px;
			}
		}
	}

	.right {
		padding-left: 18px;

		img {
			width: 140px;
			height: 140px;
			padding: 3px;
			border: 1px solid #e5e5e5;
		}
	}

}

/*End down-app-popup*/

/*calo-popup*/
.calo-popup {
	.list {
		padding-left: 15px;
		margin-bottom: 30px;
	}
}

/*End calo-popup*/
.list-news-dot {
	margin-top: 15px;

	li {
		position: relative;
		padding-left: 13px;
		margin-bottom: 15px;
		font-size: 16px;
		line-height: lh(18px, 16px);

		&:before {
			content: '';
			width: 5px;
			height: 5px;
			background: #757575;
			border-radius: 50%;
			position: absolute;
			left: 0;
			top: 6px;
		}
	}

	a {
		color: #076DB6;
		text-decoration: underline;
	}
}

.error-text {
	font-size: 13px;
	line-height: lh(15px, 13px);
	color: $vne_color;
}

/*bmi-popup*/
.bmi-popup {
	a {
		color: #076DB6;
		text-decoration: underline;
		text-underline-position: under;
	}
}

/*End bmi-popup*/

/*Rate*/
.rate-popup {
	.vote_rating_box {
		padding: 0;
		margin-bottom: 0;
		border: none;
		text-align: center;

		.btn_vne {
			width: 140px;
			font-size: 17px;
		}
	}

	.rating {
		justify-content: center;
		margin-bottom: 25px;
		width: 100%;
	}

	textarea {
		height: 96px;
		border: 1px solid #E5E5E5;
		border-radius: 3px;
		font-size: 15px;
		line-height: 1.6;
		font-family: arial;
		padding: 15px;
		resize: none;
		color: #222;
	}

}

.vote_rating_box {
	width: 100%;
	float: left;
	text-align: left;
	background: #FFFFFF;
	margin-bottom: 15px;

	.item_row_bx {
		margin-bottom: 15px;
	}

	.title-box-vote {
		font-size: 14px;
		margin-bottom: 10px;
		color: #757575;
		line-height: 16px;
		float: left;
	}

	.title_bx {
		font-size: 20px;
		margin-bottom: 15px;
	}

	.label_check {
		font-size: 18px;
		line-height: 21px;

		.radio_check {
			top: 4px;
		}
	}

	.span_bh {
		padding-left: 0 !important;
		padding-top: 10px;
		float: left;
	}
}

.rating {
	margin-bottom: 15px;
	justify-content: flex-start;
	width: auto;
	float: left;
}

.item-point {
	min-width: 155px;
	margin-right: 35px;

	.row-number {
		align-items: center;
	}

	.star-point {
		margin-right: 10px;

		.ic {
			width: 40px;
			height: 40px;
			fill: #F2C94C;
		}
	}

	.number-star {
		font-size: 18px;
		color: #757575;

		.point-bold {
			font-size: 24px;
			font-weight: 700;
			color: #222;
		}

		.point-small {
			line-height: 26px;
		}

		span {
			display: inline-block;
			vertical-align: bottom;
		}
	}

	.txt-point {
		font-size: 16px;
		margin-top: 10px;
	}

	&.point-your {
		margin-right: 0;
		cursor: pointer;

		.star-point {
			.ic {
				fill: #E5E5E5;
			}
		}

		.number-star {
			.point-bold {
				color: #757575;
			}
		}

		&.your-rate {
			.star-point {
				.ic {
					fill: #0887E2;
				}
			}

			.number-star {
				.point-bold {
					color: #222;
				}
			}
		}
	}
}

.title-rate-pop {
	font: 700 18px/160% $font-title;
	text-align: left;
	margin-bottom: 15px !important;
	@extend %font-fea;
}

.block_one_start {
	width: 40px;
	height: 40px;
	font-size: 0;
	margin: 0 20px 0 0;

	.star {
		display: inline-block;
		width: 20px;
		overflow: hidden;

		.ic {
			fill: #E5E5E5;
			width: 40px;
			height: 40px;
		}

		&.star-right {
			.ic {
				margin-left: -20px;
			}
		}
	}

	.star.hover,
	.star:hover {
		.ic {
			fill: #0887E2;
		}
	}

}

/*End Rate*/

.box-result-percent {
	.item-result-per {
		width: 100%;
		float: left;
		margin-bottom: 20px;

		.text-ans {
			width: 205px;
			font-size: 18px;
			color: #757575;
		}

		.count-person {
			width: 110px;
			text-align: right;
			font-size: 14px;
			color: #757575;

			.number-person {
				font-size: 18px;
				color: #222;
			}
		}

		.percent-bar {
			width: calc(100% - 315px);
			font-size: 14px;
			color: $vne_color;
			text-align: right;
			padding-left: 55px;
			position: relative;

			.number-per {
				width: 40px;
				position: absolute;
				top: 0;
				left: 0;
			}

			.main-bar-per {
				width: 100%;
				float: left;
				height: 4px;
				background: #E5E5E5;
				border-radius: 3px;
				position: relative;
				margin-top: 13px;

				.real-bar-per {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					background: $vne_color;
					border-radius: 3px;
				}
			}
		}

		&.result-of-you {
			.text-ans {
				color: #222;
				font-weight: 700;
			}
		}
	}

	&.box-result-percent-rating {
		.item-result-per {
			.text-ans {
				width: 40px;
			}

			.percent-bar {
				width: calc(100% - 150px);
			}
		}
	}
}

.rate-popup-result {
	.ques_vote {
		padding-bottom: 15px;
		border-bottom: 1px solid #E5E5E5;
		margin-bottom: 20px;

		p {
			margin-bottom: 5px;

			&.date_vote {
				font-size: 14px;
				line-height: 17px;
				color: #757575;
			}
		}
	}

	.box-result-percent .item-result-per .text-ans {
		color: #222;
	}

	.box-result-percent .item-result-per .percent-bar .number-per {
		top: 4px;
	}

	.total_vote {
		padding-top: 17px;
		border-top: 1px solid #E5E5E5;
		text-align: right;
		margin-bottom: 0;
		font-size: 14px;
		color: #757575;

		.number-person {
			color: $vne_color;
			font-size: 18px;
		}
	}
}

.col-left-res {
	width: calc(100% - 315px);
	padding-right: 15px !important;
	border-right: 1px solid #e5e5e5 !important;

	&.vote_rating_box {
		text-align: left;

		.count-vote {
			margin-left: 0;
			margin-bottom: 0;
			float: left;
			margin-top: 15px;
			width: 100%;
			text-align: right;
			font-size: 14px;
		}

		.title_bx {
			font-size: 18px;
		}

		.txt-kq-vote {
			font-size: 17px;
		}
	}
}

.col-right-res {
	width: 315px;
	padding-left: 15px;
}

// Custom popup ngôi sao
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
textarea,
select {
	font: 400 16px/1.4 Arial;
}

.lightbox_vipham {
	.width_common {
		float: none;
	}
}

// .white-popup {
// 	.width_common {
// 		float: none;
// 	}
// }

.form_box {
	.title_box_category {
		margin: 0;
	}
}

.form_element {
	font: 400 16px/1.4 Arial;
	/*width: 80%;*/
	margin: 0 auto;

	li {
		margin: 0 0 10px 0;

		&:last-child {
			margin: 0;
		}
	}
}

.label_form {
	width: 100%;
	display: block;
	padding: 0 0 5px 0;
	color: #000;
}

.input_form {
	width: 100%;
	border: 1px solid #dfdfdf;
	border-radius: 4px;
	color: #666;
	font: 400 16px/1.4 Arial;
	height: 40px;
	padding: 2px 5px;
	background: #fff;
	outline: none;

	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: #999;
	}

	&::-moz-placeholder {
		/* Firefox 19+ */
		color: #999;
	}

	&:-ms-input-placeholder {
		/* IE 10+ */
		color: #999;
	}

	&:-moz-placeholder {
		/* Firefox 18- */
		color: #999;
	}
}

.input_area {
	height: 100px;
}

.txt_error {
	font: 400 16px arial;
	color: #ff012f;
	margin-top: 5px;
}

.input_70 {
	width: 69% !important;
}

.input_30 {
	width: 29% !important;
}

.input_50 {
	width: 49% !important;
}

.capcha {
	float: left;
	margin: 3px 10px;
}

.txt_clear_all {
	background: none;
	padding: 5px 8px;
	text-decoration: underline;
	color: #333 !important;
}

/*Lightbox Đăng nhập*/
.lightbox_dangnhap {
	.form_element {
		width: 100%;
	}

	.login_title {
		font: 700 14px arial;
		color: #000;
		margin-bottom: 5px;
	}

	.content_poup {
		display: flex;
		flex-wrap: wrap
	}

	.login-left {
		width: 40%;
		padding-right: 20px;

		.login_title {
			text-align: right;
		}
	}

	.login-right {
		width: 60%;
		padding-left: 20px;
		border-left: 1px solid #dedede;

		.login_title {
			text-align: left;
		}
	}

	.p_social {
		a {
			width: 134px;
			height: 30px;
			line-height: 30px;
			display: block;
			text-align: center;
			color: #fff;
			float: right;
			margin-bottom: 10px;

			i {
				color: #fff;
				float: left;
				height: 30px;
				width: 30px;
				line-height: 30px;
				border-right: 1px solid rgba(255, 255, 255, 0.27)
			}

			.icon-ns {
				color: #fff;
			}
		}

		.facebook {
			background: #3B589C;
		}

		.google {
			background: #DA4835;
		}
	}

}

/*End Lightbox Đăng nhập*/

#popup-sn1 {
	background: url(images/graphics/bg_quiz1.png) no-repeat;
	max-width: 100%;
	background-size: contain;
	height: 531px;
	position: relative;
	width: 436px;
	box-shadow: none;

	.send_comment {
		background: linear-gradient(98.13deg, #C8A44F -0.03%, #F3E4BD 100.75%, #C8A44F 100.76%);
		border-radius: 4px;
		padding: 11px 0;
		line-height: 42px;
		color: #17191A;
		font: 700 16px arial;
		display: inline-block;
		width: 164.32px;
		text-align: center;
		position: absolute;
		bottom: 30px;
		left: 50%;
		transform: translate(-50%, 0%);
		-webkit-transform: translate(-50%, 0%);
		-moz-transform: translate(-50%, 0%);
		-ms-transform: translate(-50%, 0%);

		&:hover {
			opacity: .9;
		}
	}

	.close_pop {
		.mfp-close {
			line-height: 38px;
			opacity: 1;
			right: 28px;
			top: 39px;
			background: #fff;
			border-radius: 50%;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;

		}
	}
}

.popup-sn-fixed {
	position: fixed;
	bottom: 10px;
	right: 10px;
	z-index: 9;
}

.email-popup {
	width: 600px;
	font-size: px_to_rem(16px);

	form {
		.form-group {
			margin-bottom: 15px;

			label {
				font: bold px_to_rem(18px) $fontmain;
				display: block;
				margin-bottom: 10px;
				color: #666;
			}

			.input_form {
				float: none;
				border: 1px solid #dfdfdf;
				border-radius: 4px;
			}

			input {
				height: 40px;
			}

			select {
				width: 100%;
				float: none;
				border: 1px solid #dfdfdf;
				border-radius: 4px;
				color: #333;
				font: 400 1rem arial;
				height: 40px;
				padding: 2px 10px;
				background: #fff;
				outline: none;
			}

			textarea {
				color: #333;
				padding: 10px;
			}

			.checkbox {
				margin: 25px 0;
				position: relative;

				label {
					padding-left: 35px;
					cursor: pointer;
					font-size: 16px;
					font-family: arial;
					font-weight: 400;

					span {
						position: relative;
					}

					svg {
						display: none;
					}

					input {
						top: 0px;
						width: 15px;
						height: 15px;
					}

					input[type="checkbox"] {
						display: none;
					}

					span:before {
						content: "";
						position: absolute;
						width: 21px;
						height: 21px;
						top: -4px;
						left: -35px;
						border: 1px solid #dfdfdf;
						border-radius: 2px;
					}

					input[type="checkbox"]:checked+svg {
						display: inline-block;
						position: absolute;
						top: 1px;
						left: 1px;
						color: #178fd6;
						width: 13px;
						height: 13px;
						text-align: center;

						+span:before {
							border-color: $colormain;
						}
					}
				}
			}

			.mfp-close {
				position: static;
				display: inline-block;
				font-size: 14px;
				color: #999999;
				border: 0;
				border-bottom: 1px solid #ccc;
				margin-left: 10px;
				width: auto;
				border-radius: 0
			}
		}

		.btn_vne {
			border: 1px solid transparent;
			background-clip: padding-box, border-box;
			background-origin: padding-box, border-box;
			background-image: linear-gradient(#fff, #fff), linear-gradient(#16d1f2, #0799ff);
			color: #178fd6 !important;
			font-size: 16px;
			font-weight: 400;
			border-radius: 3px;
			display: inline-block;
			padding: 9px 18px;
		}
	}
}



@media screen and (max-width: 767px) {
	.lightbox_dangnhap {
		.login-left {
			width: 100%;

			.login_title {
				text-align: left;
			}
		}

		.login-right {
			width: 100%;
			border: none;
		}

		.p_social a {
			float: none;
			margin: 10px auto;
		}
	}
}

// Fix scrollbar 

.inner-pop-res {
    max-height: 600px;
    overflow-y: auto;
	overflow-x: hidden;
}

.inner-pop-res::-webkit-scrollbar {
  width: 8px;
}

.inner-pop-res::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.inner-pop-res::-webkit-scrollbar-thumb {
  background: #999;
}

.inner-pop-res::-webkit-scrollbar-thumb:hover {
  background: #666;
}
