@media screen and (min-device-width: 1600px) and (max-device-width: 2400px) {}

@media (min-width: 1200px) {
    .visible-sm {
        display: none !important;
    }
}

@media (max-width: 1199px) {
    .visible-lg {
        display: none !important;
    }
}

@media (max-width: 1100px) {
    .container {
        padding-left: 10px;
        padding-right: 10px;
    }
}