.section-beautify-fashion {
    background: #18202E;
    padding: 24px;
    position: relative;
    overflow: hidden;
    &::before {
        content: "";
        background: rgba(32, 79, 85, .4);
        width: 600px;
        height: 400px;
        position: absolute;
        border-radius: 50%;
        right: -80px;
        top: -80px;
        filter: blur(40px);
    }
    .title_box_category {
        color: #F9F9F9;
    }
    .title_news {
        color: #F7F7F7;
    }
    .description {
        color: rgba(255, 255, 255, 0.65);
    }
    .box-beautify {
        width: 681px;
        padding-right: 17px;
        border-right: 1px solid rgba(249, 249, 249, .1);
        position: relative;
        &::after {
            content: "";
            position: absolute;
            background: rgba(249, 249, 249, 0.1);
            width: 1px;
            bottom: 0;
            top: 55px;
            right: 228px;
        }
        &__left {
            width: 451px;
            padding-right: 16px;
            .art_item {
                text-align: center;
                .thumb_img {
                    padding-bottom: 72.45%;
                }
                .title_news {
                    font-size: 24px;
                }
            }
        }
        &__right {
            width: 212px;
            padding-left: 16px;
            .art_item {
                border-bottom: 1px solid rgba(249, 249, 249, .1);
                margin-bottom: 24px;
                padding-bottom: 24px;
                .title_news {
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 0;
                }
                &:last-child {
                    border-bottom: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
    .box-fashion {
        width: 361px;
        padding-left: 17px;
        .art_item {
            border-bottom: 1px solid rgba(249, 249, 249, .1);
            margin-bottom: 24px;
            padding-bottom: 24px;
            .title_news {
                font-size: 20px;
            }
            &:last-child {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
    .comment-count,
    .count_cmt {
        color: #f7f7f7
    }
    @media (max-width: 992px) {
        .box-beautify {
            width: 60%;
            &::after {
                right: 198px;
            }
        }
        .box-fashion {
            width: 40%;
            .art_item {
                .thumb_art {
                    width: 120px;
                }
                .title_news {
                    font-size: 18px
                }
            }
        }
    }
    @media (max-width: 768px) {
        .flexbox {
            flex-wrap: wrap
        }
        .box-beautify {
            width: 100%;
            &::after {
                right: 35%;
                margin-right: 11px;
            }
            &__left {
                width: 65%;
            }
            &__right {
                width: 35%;
            }
        }
        .box-fashion {
            width: 100%;
            padding-left: 0;
            margin-top: 20px;
        }
    }
}

//  BOX ẢNH
.section-box-anh {
    .item {
        position: relative;
        h3 {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 16px 20px;
            font: 500 18px var(--font1);
            color: #fff;
            z-index: 2;
        }
        .thumb_img {
            &::before {
                content: "";
                background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
                width: 100%;
                height: 134px;
                position: absolute;
                bottom: 0;
                z-index: 1;
                transform: rotate(-180deg);
            }
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 157.35%);
        width: 34px;
        height: 75px;
        line-height: 75px;
        color: #fff;
        border-radius: 0;
        margin-top: -37px;
        width: 34px;
        height: 75px;
        color: #fff;
    }
}

// BOX ĂN CHƠI
.box-an-choi {
    background: #EAEEEF;
    padding: 24px;
    .line-box {
        border-bottom: 1px solid #DFDFDF;
        margin-bottom: 16px;
        padding-bottom: 16px;
    }
    &__right {
        width: 205px;
        .art_item {
            .title_news {
                font-size: 20px
            }
        }
    }
    &__left {
        width: 480px;
        width: calc(100% - 205px);
        padding-right: 24px;
        .art_item {
            .title_news {
                font-size: 24px
            }
        }
    }
    .grid {
        column-gap: 22px;
        row-gap: 22px;
    }
    &.bg-white {
        background: #fff;
        padding: 0;
        .box-an-choi__right {
            .art_item {
                margin-bottom: 24px;
                .title_news {
                    font-size: 17px;
                    margin-bottom: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

// BOX VIDEO
.section-box-video {
    background: #1B1D23;
    padding: 18px 0 29px 0;
    position: relative;
    &::before {
        content: "";
        background: linear-gradient(92.77deg, #0C91FF 0.41%, #1893EC 23.37%, #BEE632 98.71%);
        width: 100%;
        height: 6px;
        position: absolute;
        top: 0
    }
    .title_box_category {
        color: #fff;
        padding-bottom: 0;
        margin-bottom: 12px;
        &::before {
            display: none
        }
    }
    .lbl {
        color: #fff;
        text-transform: uppercase;
        color: #EAEAEA;
        font-weight: bold;
        margin-bottom: 12px;
        padding-left: 18px;
    }
    .comment-count,
    .count_cmt {
        color: #f7f7f7
    }
    .container {
        display: flex
    }
    .art_item {
        .title_news {
            color: #EAEAEA;
            font-size: 16px
        }
    }
    .box-player-video {
        width: 718px;
        padding-right: 18px;
        position: relative;
        &::before {
            content: "";
            background: rgba(249, 249, 249, 0.1);
            height: 1px;
            left: 0;
            right: 18px;
            bottom: -5px;
            position: absolute;
        }
        .art_item {
            .thumb_art {
                margin-bottom: 13px;
            }
            .title_news {
                color: #FFFFFF;
                font-size: 20px;
                margin-bottom: 0;
            }
        }
    }
    .list_video {
        width: 382px;
        padding-top: 10px;
        position: relative;
        &::before {
            content: "";
            background: rgba(249, 249, 249, 0.1);
            height: 1px;
            left: 18px;
            right: 0;
            bottom: -5px;
            position: absolute;
        }
        .line-left {
            border-left: 1px solid rgba(249, 249, 249, 0.1);
            padding-left: 18px;
            padding-bottom: 10px;
            float: left;
            width: 100%;
        }
        .art_item {
            border-bottom: 1px solid rgba(249, 249, 249, 0.1);
            margin-bottom: 16px;
            padding-bottom: 16px;
            .thumb_img {
                padding-bottom: 60%;
            }
            &:last-child {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
        .number-info {
            color: #999
        }
    }
    @media (max-width: 992px) {
        .list_video {
            .art_item {
                &:nth-child(3) {
                    border-bottom: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
                &:nth-child(4) {
                    display: none
                }
            }
        }
    }
    @media (max-width: 768px) {
        padding: 18px 0;
        .container {
            flex-wrap: wrap
        }
        .lbl {
            padding-left: 0;
        }
        .box-player-video {
            width: 100%;
            padding-right: 0;
            margin-bottom: 30px;
            &::before {
                bottom: -15px
            }
        }
        .list_video {
            width: 100%;
            .line-left {
                border-left: 0;
                padding-left: 0;
                padding-bottom: 0;
            }
            &:before {
                display: none
            }
        }
    }
}

// BOX TIÊU ĐIỂM
.box_tieudiem {
    .head-tieudiem {
        position: relative;
        padding-top: 12px;
        margin-bottom: 24px;
        &::before {
            content: "";
            background: linear-gradient(92.77deg, #0C91FF 0.41%, #1893EC 23.37%, #BEE632 98.71%);
            position: absolute;
            top: 0px;
            height: 2px;
            width: 100%;
        }
        .title {
            color: #1881BF;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 4px;
        }
        .title_news {
            font: bold 24px var(--font1)
        }
    }
    .list_news {
        margin-bottom: 30px;
        .swiper-slide {
            background: #fff;
            height: auto;
        }
        .art_item {
            .title_news {
                font-size: 18px
            }
            .thumb_art {
                margin-bottom: 0;
            }
            .content {
                padding: 16px;
            }
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 157.35%);
        width: 34px;
        height: 75px;
        line-height: 75px;
        color: #fff;
        border-radius: 0;
        top: 40px;
        width: 34px;
        height: 75px;
        color: #fff;
        svg {
            width: 20px;
            height: 20px;
        }
    }
    .swiper-button-prev {
        transform: matrix(-1, 0, 0, 1, 0, 0);
        svg {
            transform: matrix(-1, 0, 0, 1, 0, 0);
        }
    }
}

.box_quote {
    .art_item {
        border-bottom: 1px solid #EAEAEA;
        padding-bottom: 15px;
        padding-bottom: 15px;
        .description {
            color: #333333;
            font: 400 16px/1.3 var(--font1);
            margin-bottom: 12px;
        }
        .flexbox {
            align-items: center
        }
        .ava {
            width: 48px;
            height: 48px;
            margin-right: 8px;
            .thumb_img {
                border-radius: 50%
            }
        }
        .name {
            color: #757575;
            p {
                font: 600 14px/24px var(--font1);
                color: #222222;
            }
        }
        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}

.box-grid-article {
    padding: 30px 0;
    .mb64 {
        margin-bottom: 64px;
    }
    .grid {
        column-gap: 48px;
        row-gap: 64px;
        .td {
            position: relative;
            &:nth-child(2),
            &:nth-child(5) {
                &::before {
                    content: "";
                    background: #EAEAEA;
                    width: 1px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -24px;
                }
                &::after {
                    content: "";
                    background: #EAEAEA;
                    width: 1px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: -24px;
                }
            }
        }
    }
    .art_item {
        border-bottom: 1px solid #EAEAEA;
        padding-bottom: 12px;
        margin-bottom: 12px;
        .thumb_art {
            width: 120px;
            margin-right: 16px;
        }
        .title_news {
            margin-bottom: 0;
            font-size: 18px;
            color: #333333;
        }
        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        &.thumb-full {
            .thumb_art {
                width: 100%;
                margin-right: 0;
            }
        }
    }
    @media (max-width: 768px) {
        overflow: hidden;
        .grid {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 30px;
            row-gap: 30px;
            .td {
                &:nth-child(2n),
                &:nth-child(5) {
                    &::before {
                        content: "";
                        background: #EAEAEA;
                        width: 1px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: -15px;
                    }
                    &::after {
                        display: none
                    }
                }
            }
        }
    }
}

// Taxonomy SEO
.taxonomy_seo {
    clear: both;
    font-size: 16px;
    position: relative;
    max-height: 480px;
    overflow: hidden;
    margin-bottom: 20px;
    &.open {
        max-height: 100%;
        overflow: visible;
        padding-bottom: 40px;
        transition: max-height 0.8s ease-in;
        .shrink-overflow-gradient::before {
            display: none;
        }
        .xem-them {
            display: none;
        }
        .rut-gon {
            display: block !important;
        }
    }
    p {
        margin-bottom: 15px;
        line-height: 1.6;
    }
    a {
        color: #076db6;
        position: relative;
        text-decoration: underline;
        text-underline-position: under;
    }
    table {
        width: 100%;
        margin-bottom: 20px;
        border: 1px solid #e5e5e5;
        thead {
            tr {
                th {
                    background: #eaecf0;
                    font-weight: bold;
                    padding: 7px 6px;
                    border-right: 1px solid #e5e5e5;
                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }
        tr {
            td {
                padding: 7px 6px;
                border-top: 1px solid #e5e5e5;
                border-right: 1px solid #e5e5e5;
            }
            &:nth-child(2n + 1) {
                td {
                    background: #f7f7f7;
                }
            }
        }
    }
    .shrink-overflow-gradient {
        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            height: 50%;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.013) 8.1%, rgba(255, 255, 255, 0.049) 15.5%, rgba(255, 255, 255, 0.104) 22.5%, rgba(255, 255, 255, 0.175) 29%, rgba(255, 255, 255, 0.259) 35.3%, rgba(255, 255, 255, 0.352) 41.2%, rgba(255, 255, 255, 0.45) 47.1%, rgba(255, 255, 255, 0.55) 52.9%, rgba(255, 255, 255, 0.648) 58.8%, rgba(255, 255, 255, 0.741) 64.7%, rgba(255, 255, 255, 0.825) 71%, rgba(255, 255, 255, 0.896) 77.5%, rgba(255, 255, 255, 0.951) 84.5%, rgba(255, 255, 255, 0.987) 91.9%, #fff);
            pointer-events: none;
        }
    }
    .btn-loadmore {
        display: block;
        position: absolute;
        bottom: 10px;
        left: 50%;
        margin-left: -60px;
        text-align: center;
        clear: both;
        transition: 0.5s all;
        a {
            background: #e5e5e5;
            border-radius: 16px;
            font-size: 14px;
            width: 120px;
            color: #757575;
            height: 32px;
            position: relative;
            line-height: 32px;
            text-align: center;
            display: inline-block;
            text-decoration: none;
            cursor: pointer;
            .ic {
                width: 14px;
                height: 14px;
                vertical-align: middle;
                margin: -4px 5px 0 0;
            }
            &.xem-them {
                .ic {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

%font-seo {
    font-family: $font-title;
    @extend %font-fea;
    line-height: 160%;
    font-weight: 700;
    margin-bottom: 10px;
}

.txt_h1 {
    font-size: 24px;
    @extend %font-seo;
}

.txt_h2 {
    font-size: 18px;
    @extend %font-seo;
}

.ul_seo,
.ol_seo {
    padding-left: 16px;
    margin-bottom: 20px;
    li {
        font-size: 16px;
        margin-bottom: 15px;
        list-style: disc;
    }
}

.ol_seo {
    li {
        list-style: decimal;
    }
}

.box_300 {
    .table_taxonomy {
        border: 1px solid #e5e5e5;
        padding: 15px;
        .line-top {
            border-top: 1px solid #e5e5e5;
            padding-top: 10px;
        }
        table {
            margin: 0 -5px;
            width: 103%;
            width: calc(100% + 10px);
            font-size: 14px;
            tr {
                td {
                    padding: 5px;
                    line-height: 1.4;
                    vertical-align: top;
                }
                .w-120 {
                    width: 120px;
                }
                &:first-child {
                    td {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}

// End Taxonomy SEO
.page-contact {
    padding: 40px 0;
    .content-contact {
        column-gap: 106px;
        row-gap: 106px;
    }
    .col-right-contact {
        background: #fff;
        padding: 24px;
    }
    .sub-title-page {
        font: bold 20px/1.3 var(--font1);
        color: #2C2626;
        margin-bottom: 16px;
    }
}